<app-header></app-header>

<div class="spinner-mask" *ngIf="loading">
  <mat-spinner mode="indeterminate"></mat-spinner>
</div>

<mat-card class="mat-elevation-z0 intervention-list">

  <div *ngIf="!this.activeProject; else other_content" class="no-projects">
    No Project...
  </div>
  <ng-template #other_content>
    <button mat-flat-button (click)="createDialog()" class="header-button" *ngIf="canEdit">
      Add Intervention
      <mat-icon svgIcon="plus"></mat-icon>
    </button>
    <div>
      <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let intervention" style="width: 20px" matTooltip="{{intervention?.status}}">
            <mat-icon svgIcon="{{intervention?.status}}"></mat-icon>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            {{ intervention.name }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="intervention_type_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Intervention Type
          </mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            {{ intervention.intervention_type_name }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="spec_type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Specific Type
          </mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            {{ intervention.spec_type_name }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="signature_solution">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Signature Solution
          </mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            <img src="/assets/images/sig_sol_{{intervention.signature_solution}}.jpg" onerror='this.onerror=null; this.remove()' style="width:30px" />
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="sdg">
          <mat-header-cell *matHeaderCellDef mat-sort-header>SDG</mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            <img src="/assets/images/sdg_{{intervention.sdg}}.png" style="width:30px" />
          </mat-cell>
          <mat-footer-cell *matFooterCellDef><b>Total</b></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Amount
          </mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            {{ intervention.amount | number }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{totalAmount | number}}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="beneficiaries_total">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Beneficiaries
          </mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            {{ intervention.beneficiaries_total | number }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{totalBeneficiarires | number}}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            <button mat-icon-button (click)="updateDialog(intervention)">
              <mat-icon svgIcon="olovka" *ngIf="canDelete(intervention.organization)"></mat-icon>
              <mat-icon *ngIf="!canDelete(intervention.organization)" style="font-size:32px">info</mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="copy">
          <mat-header-cell *matHeaderCellDef class="copy-column">Copy</mat-header-cell>
          <mat-cell *matCellDef="let intervention" class="copy-column">
            <button mat-icon-button (click)="copyDialog(intervention)" *ngIf="canDelete(intervention.organization)"  class="copy-icon">
              <mat-icon style="font-size: 20px;">file_copy</mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="copy-column"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="report">
          <mat-header-cell *matHeaderCellDef class="copy-column">Report</mat-header-cell>
          <mat-cell *matCellDef="let intervention" class="copy-column">
            <button mat-icon-button (click)="interventionReport(intervention)"  class="copy-icon">
              <mat-icon style="font-size: 20px;">table_chart</mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="copy-column"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
          <mat-cell *matCellDef="let intervention">
            <button mat-icon-button (click)="deleteDialog(intervention)" *ngIf="canDelete(intervention.organization)">
              <mat-icon svgIcon="kanta"></mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" matTooltip={{comment(row)}}></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
      </mat-table>
    </div>
  </ng-template>
</mat-card>
