// auth-callback.component.ts
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-auth-callback',
  template: '<div style="display:flex; height:100vh; width:100vw; align-items:center; justify-content:center"><mat-spinner></mat-spinner></div>'
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const code = this.route.snapshot.queryParamMap.get('code');
    if (code) {
      this.authService.undplogin(code).subscribe(
        (response) => {
          this.router.navigate(['/']);
        },
        (error) => {
          this.router.navigate(['/login']);
          this.snackBar.open('Authentication failed', 'error', { duration: 2000 })
        }
      );
    } else {
      this.router.navigate(['/login']);
    }
  }
}
