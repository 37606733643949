import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, NG_VALIDATORS } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { MatTabGroup } from "@angular/material/tabs";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Project } from '../../project/project.model';
import { ProjectService } from '../../project/project.service';

import { SaveChangesComponent } from '../save-changes/save-changes.component';
import { INTERVENTION_STATUS, Intervention, InterventionYear, InterventionType, InterventionSpecificType, Tag } from '../intervention.model';
import { InterventionForm, InterventionYearForm, YearLocationForm } from '../intervention.form';
import { InterventionService, InterventionTypeService, InterventionSpecificTypeService, YearLocationService, TagService } from '../intervention.service';

import { SDG, SDGService } from '../sdgs.service';
import { SignatureSolution, SignatureSolutionService } from '../signature-solution.service';

import { LocationService } from "../../locations/locations.service";
import { MatSelectChange } from '@angular/material/select';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { InterventionYearCopyComponent } from '../intervention-year-copy/intervention-year-copy.component';
import { Beneficiary } from 'src/app/beneficiaries/beneficiaries.model';
import { BeneficiaryForm } from 'src/app/beneficiaries/beneficiaries.form';
import { FundingForm } from 'src/app/funding/funding.form';
import { Funding } from 'src/app/funding/funding.model';
import { BeneficiaryService } from 'src/app/beneficiaries/beneficiaries.service';
import { FundingService } from 'src/app/funding/funding.service';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NamevaluePairService } from 'src/app/namevalue/namevalue-pair.service';
import { NamevalueForm } from 'src/app/namevalue/namevalue.form';
import { Namevalue } from 'src/app/namevalue/namevalue.model';
import { NamevalueService } from 'src/app/namevalue/namevalue.service';


@Component({
  selector: 'app-intervention-details',
  templateUrl: './intervention-details.component.html',
  styleUrls: ['./intervention-details.component.scss'],
})
export class InterventionDetailsComponent implements OnInit {

  sdgs: SDG[] = [];
  projects: Project[] = [];
  interventionTypes: InterventionType[] = [];
  interventionSpecTypes: InterventionSpecificType[] = [];
  specTypes = [];
  solutions: SignatureSolution[] = [];
  interventionStatus = INTERVENTION_STATUS;
  activeProject: Project;
  matFuBeTabGroup;
  canEdit: boolean;

  tagCtrl = new FormControl('');
  filteredTags: Observable<Tag[]>;
  tags: Tag[] = [];
  allTags: Tag[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  interventionDetailsForm = this.formBuilder.group(new InterventionForm(new Intervention()));
  years = this.interventionDetailsForm.get('years') as FormArray;
  currentIndex = 0;
  selectYears = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private sdgService: SDGService,
    private projectService: ProjectService,
    private interventionService: InterventionService,
    private interventionTypeService: InterventionTypeService,
    private interventionSpecificTypeService: InterventionSpecificTypeService,
    private solutionService: SignatureSolutionService,
    private beneficiaryService: BeneficiaryService,
    private fundingService: FundingService,
    private namevalueService: NamevalueService,
    public dialog: MatDialog,
    private locationService: LocationService,
    private yearLocationService: YearLocationService,
    private tagService: TagService,
    private authenticationService: AuthenticationService
  ) {
    this.projectService.currentProject.subscribe(project => this.activeProject = project);
    this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          let intervention_id = Number(this.route.snapshot.paramMap.get('id'));
          if (!intervention_id && !this.interventionDetailsForm.valid) {
            this.interventionService.delete(this.interventionDetailsForm.value.id).subscribe(result => { });
          }
        }
      });
  }

  ngOnInit() {
    this.canEdit = true;

    let intervention_id = Number(this.route.snapshot.paramMap.get('id'));
    const currentYear = new Date().getFullYear();

    if (intervention_id === 0) {

      this.interventionDetailsForm.get('project').setValue(this.activeProject.id);
      this.interventionService.create(this.interventionDetailsForm.value).subscribe(intervention => {
        this.interventionDetailsForm.patchValue(intervention);
        for (const intervention_year of intervention.years) {
          this.years.push(this.formBuilder.group(new InterventionYearForm(new InterventionYear(intervention_year))))
        }

        this.currentIndex = this.years.value.findIndex(item => item.year === currentYear);
        if (this.currentIndex === -1) this.currentIndex = 0;

        if (this.years.value.length > 0) {
          this.yearChange(this.years.value[this.currentIndex].year)
        }

      });
    } else {

      this.interventionService.missingYears(intervention_id).subscribe(res => {
        this.interventionService.retrive(intervention_id).subscribe(intervention => {

          this.interventionDetailsForm.patchValue(intervention);
          this.interventionDetailsForm.get('tags_id').setValue(intervention.tags.map(t => t.id))
          this.tags = intervention.tags;
          
          for (const intervention_year of intervention.years) {
            this.years.push(this.formBuilder.group(new InterventionYearForm(new InterventionYear(intervention_year))))
          }
  
          this.currentIndex = this.years.value.findIndex(item => item.year === currentYear);
          if (this.currentIndex === -1) this.currentIndex = 0;
  
          const user = this.authenticationService.currentUserValue;
          this.canEdit = user.user.is_superuser || ((user.user.organization === intervention.organization) && (user.user.projects.find(p => p === intervention.project) != undefined))
          if (!this.canEdit) {
            this.interventionDetailsForm.disable();
            this.years.disable();
          }
  
          if (this.years.value.length > 0) {
            this.yearChange(this.years.value[this.currentIndex].year)
          }
  
        });
      })
    }

    this.tagService.list('intervention').subscribe( data => {
      this.allTags = [... data]
      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string) => (tag ? this._filter(tag) : this.allTags.slice())),
      );
    })

    this.sdgService.list().subscribe(data => {
      this.sdgs = data;
    });

    this.projectService.list().subscribe(data => {
      this.projects = data;
    });

    this.interventionTypeService.list().subscribe(data => {
      this.interventionTypes = data;
    });

    this.interventionSpecificTypeService.list().subscribe(data => {
      this.specTypes = [...data];
      this.interventionSpecTypes = data;
    });

    this.solutionService.list().subscribe(data => {
      this.solutions = data;
    });

  }

  onTypeChange(_event: any, option: any) {
    if (!_event.isUserInput) { return; }
    this.specTypes = this.interventionSpecTypes.filter(item => item.intervention_type == option.id);
  }

  onSubTypeChange(_event: any, option: any) {
    if (!_event.isUserInput) { return; }
    this.interventionDetailsForm.get('intervention_type').setValue(option.intervention_type);
  }

  onSubmit(exit: boolean) {
    if (this.interventionDetailsForm.valid) {
      this.interventionService.update(this.interventionDetailsForm.value).subscribe(result => {
        this.interventionDetailsForm = this.formBuilder.group(new InterventionForm(new Intervention()));
        this.interventionDetailsForm.patchValue(result)
        this.interventionDetailsForm.get('tags_id').setValue(result.tags.map(t => t.id))
        this.years = this.interventionDetailsForm.get('years') as FormArray;
        for (const intervention_year of result.years) {
          this.years.push(this.formBuilder.group(new InterventionYearForm(new InterventionYear(intervention_year))))
        }
        this.snackBar.open('Intervention details successfully updated', 'Close', { duration: 3000 });
      });
      if (exit) this.router.navigate(['/'], { queryParams: { project: this.activeProject.id } });
    } else {
      this.validateAllFormFields(this.interventionDetailsForm);
      this.snackBar.open('Fill all required fields', 'Close', { duration: 8000 });
      return;
    }
  }

  validateAllFormFields(formGroup) {
    for (const field in formGroup.controls) {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });

        if (!control.errors) continue;

        let year = control.parent.parent.parent.parent.parent.value.year;
        let location = control.parent.parent.parent.value;

        if (!location.location) {
          location = control.parent.value;
          year = control.parent.parent.parent.value.year;
        };

        let indexFuBe = control.parent.parent.value == location.fundings ? 0 : location.beneficiaries ? 1 : 2;
        location.location.fbTab = indexFuBe;
        this.locationService.changeLocation(location.location);
        continue;
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        for (let item of control.controls) {
          this.validateAllFormFields(item);
        }
      }
    }
  }

  exitDialog(): void {

    if ((!this.interventionDetailsForm.dirty && this.interventionDetailsForm.valid) || !this.canEdit) {
      this.router.navigate(['/'], { queryParams: { project: this.activeProject.id } });
      return;
    }

    this.interventionService.retrive(this.interventionDetailsForm.value.id).subscribe(intervention => {
      const savedRequired = intervention.name && intervention.spec_type && intervention.signature_solution && intervention.sdg;
      const dialogRef = this.dialog.open(SaveChangesComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.onSubmit(true);
          return;
        } else {
          if (!savedRequired) {
            this.interventionService.delete(intervention.id).subscribe(res => this.router.navigate(['/'], { queryParams: { project: this.activeProject.id } }))
          }
          else this.router.navigate(['/'], { queryParams: { project: this.activeProject.id } });
        }
      })
    })
  }

  yearChange(year: number) {
    let yearObj = this.years.value.find(item => item.year === year);
    if (yearObj.locations.length > 0) {
      this.locationService.changeLocation(yearObj.locations[0].location);
      return;
    }
    this.locationService.changeLocation(null);
  }

  yearChangeSelect(event: MatSelectChange) {
    this.currentIndex = this.years.value.findIndex(item => item.year === event.value);
    let yearObj = this.years.value.find(item => item.year === event.value);
    if (yearObj.locations.length > 0) {
      this.locationService.changeLocation(yearObj.locations[0].location);
      return;
    }
    this.locationService.changeLocation(null);
  }

  async copyYearDialog(): Promise<void> {
    const dialogRef = this.dialog.open(InterventionYearCopyComponent, {
      data: {
        intervention: this.interventionDetailsForm.value,
        year: this.currentIndex,
        years: this.years
      }

    });

    await dialogRef.afterClosed().toPromise()
  }

  async copyLocation(event: any) {
    try {
      const { selectedYear, selectedLocation, overwrite, currentYear, index } = event
      const loc = this.years.controls[currentYear].get('locations') as FormArray
      const currentLocation = loc.controls[index].value

      let beneficiaries: FormArray;
      let fundings: FormArray;
      let namevaluelocations: FormArray;
      let locNew = this.years.controls[selectedYear].get('locations') as FormArray

      let yearLocationNew = locNew.value.find(l => l.location.id === selectedLocation.id)
      let indexNew = locNew.value.findIndex(l => l.location.id === selectedLocation.id);
      
      if (yearLocationNew === undefined) {
        const selectedYearValue = this.years.controls[selectedYear].value
        yearLocationNew = await this.yearLocationService.create({ ...currentLocation, location: selectedLocation, intervention_year: selectedYearValue.id, fundings: [], beneficiaries: [], namevaluelocations:[], tags_id: currentLocation.tags.map( t => t.id) }).toPromise();
        let yearLocationForm = new YearLocationForm(yearLocationNew);
        const values = [...locNew.value]
        values.push(yearLocationNew);
        const sortedLocations = values.sort((a, b) => a.location.name.localeCompare(b.location.name))
        indexNew = sortedLocations.findIndex(a => a.id === yearLocationNew.id)
        locNew.insert(indexNew, this.formBuilder.group(yearLocationForm));
      }

      locNew.controls[indexNew].setValue({ ...yearLocationNew, action: currentLocation.action, status: currentLocation.status, tags_id: currentLocation.tags.map( t => t.id) })
      beneficiaries = locNew.controls[indexNew].get('beneficiaries') as FormArray;
      fundings = locNew.controls[indexNew].get('fundings') as FormArray;
      namevaluelocations = locNew.controls[indexNew].get('namevaluelocations') as FormArray;
     
      if (overwrite) {
        for (let beneficiary of yearLocationNew.beneficiaries) {
          if (beneficiary.id) await this.beneficiaryService.delete(beneficiary.id).toPromise()
        }
        beneficiaries.clear()
        for (let funding of yearLocationNew.fundings) {
          if (funding.id) await this.fundingService.delete(funding.id).toPromise()
        }
        fundings.clear()

        for (let namevaluelocation of yearLocationNew.namevaluelocations) {
          if (namevaluelocation.id) await this.namevalueService.delete(namevaluelocation.id).toPromise()
        }
        namevaluelocations.clear()
      }
      
      for (let beneficiary of currentLocation.beneficiaries) {
        let beneficiaryNew = new Beneficiary({ ...beneficiary, id: null });
        beneficiaryNew.intervention_year_location = yearLocationNew.id;
        let beneficiaryForm = this.formBuilder.group(new BeneficiaryForm(beneficiaryNew));
        beneficiaries.insert(0, beneficiaryForm);
      }
      for (let funding of currentLocation.fundings) {
        let fundingNew = new Funding({ ...funding, id: null });
        fundingNew.intervention_year_location = yearLocationNew.id;
        fundings.insert(0, this.formBuilder.group(new FundingForm(fundingNew)));
      }
      
      let addNameValue = [];
      if(overwrite){
        addNameValue = currentLocation.namevaluelocations;
      } else {
        const array = yearLocationNew.namevaluelocations.map((nv) => nv.namevalue)
        addNameValue = currentLocation.namevaluelocations.filter( nv => !array.includes(nv.namevalue))
      }
      
      for (let namevalue of addNameValue) {
        let namevalueNew = new Namevalue({ ...namevalue, id: null });
        namevalueNew.intervention_year_location = yearLocationNew.id;
        namevaluelocations.insert(0, this.formBuilder.group(new NamevalueForm(namevalueNew)));
      }
      this.snackBar.open('Copied successfully!', 'copy', {
        duration: 2000,
      });

     } 
    catch (error) {
      this.snackBar.open('Failed to copy location!', 'copy', {
        duration: 2000,
      });

    }
  }

  remove(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.interventionDetailsForm.get('tags_id').value.splice(index, 1)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const id: number = event.option.value
    const tag = this.tags.find( t => t.id === id )

    if(tag === undefined){
      const t = this.allTags.find(t => t.id ===id)
      this.tags.push(t)
      this.interventionDetailsForm.get('tags_id').value.push(t.id)
    }
    this.tagInput.nativeElement.value = null;
    this.tagCtrl.setValue(null);
    this.tagCtrl.disable();
    this.tagCtrl.enable();
  }

  private _filter(value: string): Tag[] {
    const filterValue = (''+value).toLowerCase();

    return this.allTags.filter(tag => tag.name.toLowerCase().includes(filterValue));
  }

}
