import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

import { InterventionListComponent } from './intervention/intervention-list/intervention-list.component';
import { InterventionDetailsComponent } from './intervention/intervention-details/intervention-details.component';

import { LoginComponent } from "./login/login.component";
import { ProjectReportComponent } from './project/project-report/project-report.component';
import { InterventionReportComponent } from './intervention/intervention-report/intervention-report.component';
import { AuthCallbackComponent } from './login/authcallback.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path:'auth/callback',
    component: AuthCallbackComponent
  },
  {
    path: 'intervention/create',
    component: InterventionDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'intervention/:id',
    component: InterventionDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'projectreport/:id',
    component: ProjectReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'interventionreport/:id',
    component: InterventionReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: InterventionListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
