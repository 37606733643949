import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { APP_BASE_HREF } from "@angular/common";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { InterventionsModule } from './intervention/intervention.module';
import { LocationsModule } from './locations/locations.module';
import { FundingBeneficiariesModule } from './funding-beneficiaries/funding-beneficiaries.module';
import { ProjectModule } from './project/project.module';
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { LoginModule } from "./login/login.module";
import { UserModule } from "./user/user.module";

import { FundingModule } from './funding/funding.module';
import { BeneficiariesModule } from './beneficiaries/beneficiaries.module';
import { HeaderModule } from './header/header.module';
import { NamevalueModule} from './namevalue/namevalue.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UserModule,
    LoginModule,
    ProjectModule,
    LocationsModule,
    InterventionsModule,
    FundingModule,
    BeneficiariesModule,
    NamevalueModule,
    FundingBeneficiariesModule,
    HeaderModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'en-US' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
